<template>
  <div class="satisfaction-records-action">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" :content="content" />
    <ml-tip class="scroll-box" :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <!-- 表单 -->
        <ml-form
          ref="satisfactionRef"
          labelWidth="200px"
          style="width: 1200px; margin: 40px auto"
          :model="satisfactionForm"
          :rules="satisfactionFormRules"
        >
          <!-- 提示标题 -->
          <el-divider content-position="left" style="font-weight: bold">月度评价信息</el-divider>
          <el-row>
            <el-col :span="12">
              <!-- 阿米巴 -->
              <ml-select
                prop="companyId"
                placeholder="请选择阿米巴"
                :options="unitOptions"
                label="阿米巴:"
                keyName="companyId"
                labelName="companyName"
                valueName="companyId"
                v-model="satisfactionForm.companyId"
                @change="selectUnit"
              />
            </el-col>
            <el-col :span="12">
              <!-- 所属项目 -->
              <ml-select
                prop="projectId"
                placeholder="请选择所属项目"
                :options="projectData"
                label="所属项目:"
                keyName="projectId"
                labelName="projectName"
                valueName="projectId"
                v-model="satisfactionForm.projectId"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 日期 -->
              <!-- 日期 -->
              <ml-date-picker
                prop="month"
                placeholder="请选择年月"
                label="年月:"
                type="month"
                v-model="satisfactionForm.month"
              />
            </el-col>
            <el-col :span="12"> </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 输入框-工程师服务态度 -->
              <ml-input-number
                :max="100"
                :min="0"
                prop="attitude"
                label="工程师服务态度:"
                placeholder="请输入工程师服务态度"
                v-model="satisfactionForm.attitude"
              />
            </el-col>
            <el-col :span="12">
              <!-- 所属项目 -->
              <ml-input-number
                :max="100"
                :min="0"
                prop="appearance"
                label="工程师仪表仪态:"
                placeholder="请输入工程师仪表仪态"
                v-model="satisfactionForm.appearance"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 输入框-工程师技术能力 -->
              <ml-input-number
                :max="100"
                :min="0"
                prop="capability"
                label="工程师技术能力:"
                placeholder="请输入工程师技术能力"
                v-model="satisfactionForm.capability"
              />
            </el-col>
            <el-col :span="12">
              <!-- 输入框-服务操作规范性 -->
              <ml-input-number
                :max="100"
                :min="0"
                prop="normative"
                label="服务操作规范性:"
                placeholder="请输入服务操作规范性"
                v-model="satisfactionForm.normative"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 输入框-响应及时性 -->
              <ml-input-number
                :max="100"
                :min="0"
                prop="timeliness"
                label="响应及时性:"
                placeholder="请输入响应及时性"
                v-model="satisfactionForm.timeliness"
              />
            </el-col>
            <el-col :span="12">
              <!-- 输入框-故障处理效果 -->
              <ml-input-number
                :max="100"
                :min="0"
                prop="quality"
                label="故障处理效果:"
                placeholder="请输入故障处理效果"
                v-model="satisfactionForm.quality"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 输入框-配件齐全 -->
              <ml-input-number
                :max="100"
                :min="0"
                prop="completely"
                label="配件齐全:"
                placeholder="请输入配件齐全"
                v-model="satisfactionForm.completely"
              />
            </el-col>
            <el-col :span="12">
              <!-- 输入框-服务单等资料提交情况 -->
              <ml-input-number
                :max="100"
                :min="0"
                prop="holistic"
                label="服务单等资料提交情况:"
                placeholder="请输入服务单等资料提交情况"
                v-model="satisfactionForm.holistic"
              />
            </el-col>
          </el-row>

          <!-- 提交按钮 -->
          <ml-button
            submitText="提交"
            submitIcon="el-icon-upload2"
            cancelIcon="el-icon-circle-close"
            cancelText="返回上一页"
            @click-submit="clickSubmitUser"
            @click-cancel="clickCancelUser"
          />
        </ml-form>
      </el-scrollbar>
    </ml-tip>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { getTabberData, searchParams, tabberHeight } from '@/utils'
export default {
  name: 'SatisfactionRecordsAction',
  setup() {
    const { commit, getters, dispatch } = useStore()
    const route = useRoute()
    const router = useRouter()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    // 获取路径参数
    const { evaluateId } = route.query
    // 设置导航标题文本
    const content = ref(evaluateId ? '修改月度评价' : '添加月度评价')
    /* 开始 用户表单 **********************************/
    // 表单配置
    const satisfactionRef = ref()
    const satisfactionForm = reactive({
      companyId: '',
      projectId: '',
      appearance: '',
      attitude: '',
      capability: '',
      normative: '',
      timeliness: '',
      quality: '',
      completely: '',
      holistic: '',
      month: ''
    })
    // 单位名下拉列表
    const unitOptions = ref([])
    // 所属项目配置
    const projectData = ref([])

    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = { parent: 0 }
      if (isBusinessDepartment.value) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    const selectUnit = () => {
      satisfactionForm.projectId = ''
    }
    watch(
      () => satisfactionForm.companyId,
      newvalue => {
        getProjectData(newvalue)
      }
    )
    // 账户新增修改校验
    const satisfactionFormRules = {
      companyId: [{ required: true, message: '请选择阿米巴', trigger: 'blur' }],
      projectId: [{ required: true, message: '请选择项目', trigger: 'blur' }],
      appearance: [{ required: true, message: '请输入工程师仪表仪态', trigger: 'blur' }],
      attitude: [{ required: true, message: '请输入工程师服务态度', trigger: 'blur' }],
      capability: [{ required: true, message: '请输入工程师技术能力', trigger: 'blur' }],
      normative: [{ required: true, message: '请输入服务操作规范性', trigger: 'blur' }],
      timeliness: [{ required: true, message: '请输入响应及时性', trigger: 'blur' }],
      quality: [{ required: true, message: '请输入故障处理效果', trigger: 'blur' }],
      completely: [{ required: true, message: '请输入配件齐全', trigger: 'blur' }],
      holistic: [{ required: true, message: '请输入服务单等资料提交情况', trigger: 'blur' }],
      month: [{ required: true, message: '请选择年月', trigger: 'blur' }]
    }
    // 提交表单
    const clickSubmitUser = () => {
      satisfactionRef.value.CustomFormRef.validate()
        .then(() => {
          // 处理参数
          const satisfactionFormParams = searchParams(satisfactionForm, ['month'], true)
          if (satisfactionForm.month) {
            const nDate = new Date(satisfactionForm.month)
            satisfactionFormParams.year = nDate.getFullYear()
            satisfactionFormParams.month = nDate.getMonth() + 1
          }

          if (content.value === '添加月度评价') {
            dispatch('fetchAddMonthEvaluate', satisfactionFormParams).then(data => {
              if (data && data.code === 200) {
                commit('setError', {
                  status: true,
                  title: data.message,
                  message: '正在获取新的数据...',
                  type: 'success'
                })
                router.push({ path: '/satisfactionRecords' })
              }
            })
          } else {
            satisfactionFormParams.evaluateId = evaluateId
            dispatch('fetchUpdateMonthEvaluate', satisfactionFormParams).then(data => {
              if (data && data.code === 200) {
                commit('setError', {
                  status: true,
                  title: data.message,
                  message: '正在获取新的数据...',
                  type: 'success'
                })
                router.push({ path: '/satisfactionRecords' })
              }
            })
          }
        })
        .catch(() => {})
    }
    // 取消表单
    const clickCancelUser = () => {
      router.push({ path: '/satisfactionRecords' })
    }
    /* 结束 用户表单 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []
      if (data.length > 0) {
        satisfactionForm.companyId = data[0].companyId
      }

      if (content.value === '修改月度评价') {
        await dispatch('fetchGetMonthEvaluate', evaluateId).then(data => {
          if (data && data.code === 200) {
            Object.keys(satisfactionForm).map(item => {
              if (item === 'month') {
                satisfactionForm[item] = new Date(`${data.data['year']}-${data.data['month']}`)
              } else {
                satisfactionForm[item] = data.data[item]
              }
            })
          }
        })
      }
    })
    return {
      satisfactionRef,
      content,
      satisfactionForm,
      unitOptions,
      projectData,
      satisfactionFormRules,
      clickSubmitUser,
      clickCancelUser,
      selectUnit,
      tabberHeight
    }
  }
}
</script>

<style lang="scss">
.satisfaction-records-action {
  position: relative;
}
</style>
